










































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// --- Third Party imports ---
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

// --- Add Plugins to imports ---
gsap.registerPlugin(ScrollToPlugin);


@Component
export default class ReadMore extends Vue {
  @Prop(Number) readonly scrollToOffestOnClose!: number;
  @Prop(Number) readonly maxHeight!: number;

  $refs!: {
    readmoreContainer: HTMLFormElement,
    readmoreContent: HTMLFormElement,
    readMoreEl: HTMLFormElement,
  };

  readMoreActive: boolean = false;
  readMoreExpanded: boolean = false;
  timeline: any = false;

  @Watch('readMoreExpanded', {
    immediate: true,
    deep: true,
  })
  change() {
    if (this.readMoreExpanded) {
      this.readMoreAnimationEnd();
    } else {
      this.readMoreAnimationStart();
    }
  }

  @Watch('scrollToOffestOnClose', {
    immediate: true,
    deep: true,
  })
  updatedOffset() {
    this.$nextTick(() => {
      if (this.$refs.readmoreContent) {
        if (this.$refs.readmoreContent.clientHeight > this.maxHeight) {
          this.readMoreActive = true;
          this.readMoreExpanded = false;
          gsap.set(this.$refs.readmoreContainer, {duration: 0, maxHeight: this.maxHeight});
        } else {
          this.readMoreActive = false;
          this.readMoreExpanded = false;
        }
      }
    });
  }

  toggleReadMore() {
    if (!this.readMoreExpanded) {
      gsap.to(this.$refs.readmoreContainer, {
        duration: 1, 
        maxHeight: this.$refs.readmoreContent.clientHeight + 82,
        onStart: () => { this.readMoreExpanded = true; },
      });
    } else {
      gsap.to(this.$refs.readmoreContainer, {
        duration: 0.6, 
        maxHeight: this.maxHeight, 
        onStart: () => {
          if (window.scrollY > window.outerHeight) {
            gsap.to(window, {duration: 0.6, scrollTo: {y: Math.abs(this.scrollToOffestOnClose)}});
          }
          this.readMoreExpanded = false;
        }, 
      });
    }
  }

  updated() {
    if (this.$refs.readmoreContent.clientHeight > this.maxHeight) {
      this.readMoreActive = true;
    } else {
      this.readMoreActive = false;
    }
  }

  readMoreAnimationEnd() {
    if (this.timeline && this.$refs.readMoreEl) {
      this.timeline.seek(0);
      this.timeline.pause();
    }
  }

  readMoreAnimationStart() {
    if (!this.timeline && this.$refs.readMoreEl) {
      this.timeline = gsap.timeline({ repeat: -1, paused: true});
      this.timeline.to(this.$refs.readMoreEl, { duration: 0.4, top: -4, ease: 'power1.out'});
      this.timeline.to(this.$refs.readMoreEl, { duration: 0.6, top: 0, ease: 'power1.out'});
    }
    if (this.timeline) {
      this.timeline.resume();
    }
      
  }

  getClasses() {
    let classes = ''; 
    classes += this.readMoreExpanded ? ' state--open' : '';
    classes += this.readMoreActive ? ' state--active' : '';
    return classes;
  }
}











































































































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// --- Models ---
import { ConfirmedBooking, ShootBooking } from '@/models/Booking.model';

// --- Services ---
import AppService from '@/services/app';
import BookingService from '@/services/booking';

// --- Third Party ---
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

// Interface for this Component ONLY
interface CalendarItem {
  date: string;
  state: string;
  classes: string;
  bookingId?: number;
}

@Component
export default class PencilDateCalendar extends Vue {
  @Prop(Number) readonly equipmentId!: number;
  @Prop(String) readonly maxDate!: string;
  @Prop(String) readonly minDate!: string;
  @Prop(Number) readonly shootDuration!: number;
  @Prop(Number) readonly shootId!: number;

  // Used to generate dates for display
  dateItems: CalendarItem[] = [];
  newDates: CalendarItem[] = [];
  today: string = dayjs(Date()).format('YYYY-MM-DD');
  headerCopy: string = '';

  // Used to display booking information 
  pencilDate: string = '';
  allowedPencilDates: string[] = [];
  iconSize: number = 140;
  minShootDuration: number = this.$store.getters['app/maxWidthButton'](this.$vuetify.breakpoint.name, ['xs']) === '100%' ? 3 : 7;
  myPencilledDates: ShootBooking[] = [];
  unavailableDates: ConfirmedBooking[] = [];

  // If the startDate changes, the iterated dates should update
  @Watch('shootDuration', {
    immediate: true,
    deep: true,
  })
  async shootDurationChanged() {
    if (this.shootDuration > 0) {
      await this.listConfirmedBookingsByEquipment();
      await this.listShootBookingsByEquipment();

      this.setIteratedDates();
      this.setCalendarHeader();
    }
  }

  // #region Functions to display booking information data on page load
    setIteratedDates() {
      let counter = 0;
      const shootDuration = this.shootDuration + 1;
      const maxDuration = shootDuration / this.minShootDuration === 0 ? shootDuration : ((Math.floor(shootDuration / this.minShootDuration) + 1) * this.minShootDuration); 

      const initialDate = dayjs(this.minDate).subtract(1, 'day');
      this.newDates = [];

      while (counter < maxDuration) {
        this.newDates[counter] = {
          date: initialDate.add(counter, 'day').format('YYYY-MM-DD'),
          state: '',
          classes: '',
        };

        this.newDates[counter].state = this.getDateState(counter);
        this.newDates[counter].classes = 
          ('state--' + this.newDates[counter].state) + 
          (this.newDates[counter].date === this.today ? ' calendar__item--today' : '');

        counter++;
      }

      // bulk update so that the display updates accordingly with all data because this.dateItems is reactive
      this.dateItems = this.newDates;
    }

    async listConfirmedBookingsByEquipment() {    
      await BookingService.listConfirmedBookingsByEquipment({ params: {shootId: this.shootId, equipmentId: this.equipmentId } })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.unavailableDates = [];
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.unavailableDates = response.data;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('PencilDateCalendar.vue - listConfirmedBookingsByEquipment - 209 - ' + JSON.stringify(response));
          }
        });
    }

    async listShootBookingsByEquipment() {
      await BookingService.listShootBookingsByEquipment({ params: {shootId: this.shootId, equipmentId: this.equipmentId } })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.myPencilledDates = [];
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.myPencilledDates = response.data;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('PencilDateCalendar.vue - listShootBookingsByEquipment - 228 - ' + JSON.stringify(response));
          }
        });
    }
  // #endregion

  // #region Function to display calendar
    setCalendarHeader() {
      // only add 6 days because it is 7 actual days so the initial date counts as one of the days
      this.headerCopy = 
        dayjs(this.minDate).subtract(1, 'day').format('D MMMM YYYY') + 
        ' to ' + 
        dayjs(this.dateItems[this.dateItems.length - 1].date).format('D MMMM YYYY');
    }

    getDateWeekday(date: string) {
      return dayjs(date).format('ddd');
    }

    getDateDay(date: string) {
      return dayjs(date).format('D');
    }

    getDateMonth(date: string) {
      return dayjs(date).format('MMMM');
    }

    getDateState(index: number) {
      const currentDate = this.newDates[index].date;
      let itemState = index === 0 || index === this.shootDuration ? 'disabled' : 'default';

      const unavailableDay = this.unavailableDates.find((item: ConfirmedBooking) => item.date === dayjs(currentDate).format('YYYY-MM-DD'));      
      
      if (unavailableDay) {
        if (unavailableDay.shootId === this.shootId) {
          
          if (unavailableDay.isGearCheckDay) {
            itemState = 'gearcheck';
          } else {
            itemState = 'confirmed';
            // this.newDates[index].bookingId = 
          }
        } else {
          itemState = 'unavailable';
        }
      }

      const pencilBooking = this.myPencilledDates.find((item: ShootBooking) => {
        if (dayjs(item.date).format('YYYY-MM-DD') === dayjs(currentDate).format('YYYY-MM-DD')) {
          itemState = 'pencil';
          this.newDates[index].bookingId = item.bookingId;

          return true;
        }
        return false;
      });

      if (dayjs(currentDate).diff(dayjs(this.maxDate)) > 0) {
        itemState = 'disabled';
      }

      if (dayjs(currentDate).diff(dayjs(this.today)) < 345600000) {
        itemState = 'disabled';
      }

      return itemState;
    }

    getStateCopy(state: string) {
      if (state === 'gearcheck') {
        return 'gear check';
      }

      if (state === 'confirmed') {
        return 'confirmed';
      }

      if (state === 'pencil') {
        return 'booking';
      }
    }
  // #endregion

  // #region Functions to handle click events on calendar
    getDateRangeEnd(startRange: string) {
      const startRangeDate = dayjs(startRange);
      let endRange: string = '';
      let firstUnavailableDate = '';
      let firstPencilDate = '';

      // check against unavailableDates if any, if startRange is smaller than notAllowedDate then set enddate to 1 less 
      if (this.unavailableDates.length) {
        let newUnavailableDate: string = '';
        
        for (const [notAllowedIndex, notAllowedItem] of this.unavailableDates.entries()) {
          if (dayjs(notAllowedItem.date).diff(startRangeDate) > 0) {
            newUnavailableDate = dayjs(notAllowedItem.date).subtract(1, 'day').format('YYYY-MM-DD');
            if (firstUnavailableDate !== '') {
              if (dayjs(newUnavailableDate).diff(firstUnavailableDate) < 0) {
                firstUnavailableDate = newUnavailableDate;  
              }
            } else {
              firstUnavailableDate = newUnavailableDate;
            }
          }
        }

        endRange = firstUnavailableDate;
      }

      if (this.myPencilledDates.length) {
        let newUnavailableDate: string = '';
        
        for (const [notAllowedIndex, notAllowedItem] of this.myPencilledDates.entries()) {
          if (dayjs(notAllowedItem.date).diff(startRangeDate) > 0) {
            newUnavailableDate = dayjs(notAllowedItem.date).subtract(1, 'day').format('YYYY-MM-DD');
            if (firstPencilDate !== '') {
              if (dayjs(newUnavailableDate).diff(firstPencilDate) < 0) {
                firstPencilDate = newUnavailableDate;  
              }
            } else {
              firstPencilDate = newUnavailableDate;
            }
          }
        }

        if (endRange === '') {
          endRange = firstPencilDate;
        } else {
          if (dayjs(firstPencilDate).diff(firstUnavailableDate) < 0) {
            endRange = firstPencilDate;
          }
        }
      }

      // if no endrange has been set yet from not allowed dates, check if start date is less than end of shoot date and set to that
      if (firstPencilDate === '' && firstUnavailableDate === '') {
        if (dayjs(this.maxDate).diff(dayjs(startRange)) >= 86400000) {
          endRange = dayjs(this.maxDate).format('YYYY-MM-DD');
        } else {
          endRange = startRange;
        }
      }

      return endRange;
    }

    calendarItemClicked(index: number) { 
      const currentItem = this.dateItems[index];

      if (currentItem.state === 'default') {
        let currentBookingId = -1;
        
        const currentPencil = this.myPencilledDates.find((item: ShootBooking) => {
          if (dayjs(item.date).format('YYYY-MM-DD') === dayjs(currentItem.date).format('YYYY-MM-DD')) {
            currentBookingId = item.bookingId;
            return true;
          }
          return false;
        });
        
        let currentPencilBooking: ShootBooking[] = [];
        
        if (currentBookingId > -1) {
          currentPencilBooking = this.myPencilledDates.filter((item: ShootBooking) => item.bookingId === currentBookingId );
        }
  
        this.$emit('clicked:date', {
          startDate: dayjs(currentItem.date).format('YYYY-MM-DD'), 
          maxDate: this.getDateRangeEnd(dayjs(currentItem.date).format('YYYY-MM-DD')), 
          pencilBookingId: currentBookingId,
          pencilBookingStartDate: currentBookingId > -1 ? dayjs(currentPencilBooking[0].date).format('YYYY-MM-DD') : '',
          pencilBookingEndDate: currentBookingId > -1 ? dayjs(currentPencilBooking[currentPencilBooking.length - 1].date).format('YYYY-MM-DD') : '',
        });
      }
    }
  // #endregion
}

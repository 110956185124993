



































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// --- Models ---
import { LocationType } from '@/models/App.model';

// --- Services ---
import AppService from '@/services/app';
import BookingService from '@/services/booking';

// --- Third Party ---
import dayjs from 'dayjs';

@Component
export default class PencilDateDialog extends Vue {
  // Values used to determine what the ranges allowed are for the equipment pencil booking
  @Prop(Boolean) readonly showItemDialog!: boolean;
  @Prop(String) readonly dateMin!: string;
  @Prop(String) readonly dateMax!: string;
  @Prop(String) readonly dateCurrentMin!: string;
  @Prop(String) readonly dateCurrentMax!: string;
  @Prop(Boolean) readonly existingBooking!: boolean;
  @Prop(Number) readonly shootId!: number;
  @Prop(Number) readonly equipmentId!: number;
  @Prop(Number) readonly dailyRate!: number;
  @Prop(Number) readonly pencilBookingId!: number;

  // Used for the click:confirm event
  valid: boolean = false;
  title: string = 'Add Booking';
  clicked: boolean = false;
  actionType: string = '';

  // used by Date Range Picker for Booking Date
  datePicker: boolean = false;
  datePickerHeader: string = 'Select Start Date';
  dateRange: string[] = [];
  dateRangeFormatted: string = '';
  startDate: string = '';
  endDate: string = '';  
  today: string = dayjs().format('YYYY-MM-DD');
  totalDays: number = 0;
  travelDays: number = 0;
  downDays: number = 0;

  // Used by the location type selector
  locationTypes: LocationType[] = [];
  locationTypeIds: number[] = [];

  $refs!: {
    pencilDateDialog: HTMLFormElement,
    pencilDateForm: HTMLFormElement,
    travelDayInput: HTMLFormElement,
    downDayInput: HTMLFormElement,
  };
  
  // #region Funcitons for form input validation rules
    rules = {
      required: (value: string) => value != null && !!value.toString().trim() || '',
    };

    // Custom validity rule for travel days, it has to be 0 or 2+ but always 1 less than total
    checkTravelDayValidity() {
      if (this.totalDays >= 3) {
        if (this.travelDays <= (this.$store.getters['app/totalBookingDays'](this.startDate, this.endDate) - 1) && this.travelDays >= 2 && this.totalDays > (this.travelDays + this.downDays)) {
          return true;
        }
      }

      return (this.travelDays === 0);
    }

    validateTravelDays() {
      if (this.$refs.travelDayInput) {
        this.$refs.travelDayInput.validate();
      }
    }

    // Custom validity rule for down days, it has to be 0 or 1+ but always 1 less than total
    checkDownDayValidity() {
      if (this.totalDays >= 2) {
        if (this.downDays <= (this.$store.getters['app/totalBookingDays'](this.startDate, this.endDate) - 1) && this.downDays >= 1 && this.totalDays > (this.travelDays + this.downDays)) {
          return true;
        }
      }

      return (this.downDays === 0);
    }

    validateDownDays() {
      if (this.$refs.downDayInput) {
        this.$refs.downDayInput.validate();
      }
    }
  // #endregion

  // #region Functions used to display dialog & form inputs
    // Use watch to determine what type of dialog is being shown as it will have a pencilBookingId present for update
    @Watch('showItemDialog', {
      immediate: true,
      deep: true,
    })
    change() {
      if (this.pencilBookingId > -1) {
        this.dateRange = [this.dateCurrentMin, this.dateCurrentMax];
        this.startDate = this.dateCurrentMin;
        this.endDate = this.dateCurrentMax;
        this.dateRangeFormatted = this.$store.getters['app/formattedDateRange'](this.dateRange[0], this.dateRange[1], false);
        this.actionType = 'UPDATE';
      } else {
        this.startDate = this.dateMin;
        this.endDate = '';
        this.dateRange = [this.dateMin];
        this.dateRangeFormatted = dayjs(this.startDate).format('DD MMM YYYY');
        this.totalDays = 1;
        this.actionType = 'CREATE';
      }
      this.datePickerHeader = this.dateRangeFormatted + ' (' + this.totalDays + 'd)'; 

      // scroll the dialog content to the top
      this.$nextTick(() => {
        if (this.showItemDialog) {
          this.$refs.pencilDateDialog.$refs.dialog.scrollTop = 0;
        }
      });
    }

    onDatePickerToggle(opened: boolean) {
      if (!opened) {
        if (this.dateRange.length === 1) {
          this.datePickerHeader = 'Select Start Date';
          this.dateRange.push(this.dateRange[0]);
        }
      }
    }

    shootDateInput() {
      // Check if daterange has start & end date, dateRange will contain only first date if no end date
      if (this.dateRange.length === 1) {
        // single date selection
        // assign date variables to clickedItem because we need to calculate totalDays on them
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[0];
      } else {
        // Check if start & end date is actually after each other, else swap them
        if (dayjs(this.dateRange[0]).isAfter(this.dateRange[1])) {
          const temp = this.dateRange[0];
          this.dateRange[0] = this.dateRange[1];
          this.dateRange[1] = temp;
        }

        // assign date variables to because we need to calculate totalDays on them
        // if this is not assigned, totalDays calculations malfunctions because the end date is not selected
        // when selecting the first date
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[1];
      }

      // Set daterange formatted value for input field
      this.dateRangeFormatted = this.$store.getters['app/formattedDateRange'](this.startDate, this.endDate, false);
      // Calculate total Days for disabled total days input field (user info only)
      this.totalDays = this.$store.getters['app/totalBookingDays'](this.startDate, this.endDate);
      // Update the custom header with the formatted date and total days for feedback to the user on
      // what is actually selected
      this.datePickerHeader = this.$store.getters['app/formattedDateRange'](this.startDate, this.endDate, false); 
    }
  // #endregion

  // #region Functions for dialog actions update, confirm & cancel
    async beforeMount() {
      // now go and fetch the all location types
      // get location types if it hasn't been fetched already
      await AppService.listLocationTypes(false);
      this.locationTypes = this.$store.getters['app/locationTypes'];

      this.actionType = 'CREATE';
    }

    onCloseClicked() {
      this.$emit('click:cancel');
    }

    resetDialogValues() {
      this.dateRange = [];
      this.dateRangeFormatted = '';
      this.locationTypeIds = [];
      this.totalDays = 1;

      if (this.$refs.pencilDateForm) {
        ((this.$refs.pencilDateForm) as any).reset();
      }
    }

    onSaveConfirmed() {
      if (this.endDate === '') {
        this.endDate = this.startDate;
      }
      if (this.actionType === 'CREATE') {
        this.doCreate();
      }

      if (this.actionType === 'UPDATE') {
        this.doUpdate();
      }
    }

    async doCreate() {
      this.clicked = true;
      
      await BookingService.create(
        { 
          shootId: this.shootId,
          equipmentId: this.equipmentId,
          dailyRate: this.dailyRate,
          locationTypes: this.locationTypes.filter((locationType) => this.locationTypeIds.indexOf(locationType.id) > -1 ),
          startDate: this.startDate,
          endDate: this.endDate,
          travelDays: this.travelDays,
          downDays: this.downDays,
        })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.resetDialogValues();
              this.$emit('click:confirm');
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('PencilDateDialog.vue - doCreate - 371 - ' + JSON.stringify(response));
          }
        })
        .finally(() => { 
          this.clicked = false;
        });
    }

    async doUpdate() {
      this.clicked = true;

      await BookingService.update(
        { 
          id: this.shootId, // this should be the booking id
          locationTypes: this.locationTypes.filter((locationType) => this.locationTypeIds.indexOf(locationType.id) > -1 ),
          startDate: this.startDate,
          endDate: this.endDate,
          travelDays: this.travelDays,
          downDays: this.downDays,
        })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.resetDialogValues();
              this.$emit('click:confirm');
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('PencilDateDialog.vue - doUpdate - 402 - ' + JSON.stringify(response));
          }
        })
        .finally(() => { 
          this.clicked = false;
        });
    }
  // #endregion
}

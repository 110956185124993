/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Store from '@/store/index';

export default {
  resetLocationTypes() {
    // reset store to empty
    Store.dispatch('equipment/setLocationTypes', {
      locationTypes: [],
    });
  },

  async listAvailableByShoot(config: {params: { shootId: number }}) {
    return Api().get('/equipment/list_available', config);
  },

  async listAllLocationTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['equipment/locationTypes'].length < 1) {
      await Api().get('/app/list_location_types')
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.resetLocationTypes();
              return response.data.message;
            } else {
              Store.dispatch('equipment/setLocationTypes', {
                locationTypes: response.data,
              });
              return '';
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            throw new Error('response: ' + JSON.stringify(response));
          }
        })
        .catch((error) => {
          this.resetLocationTypes();
          
          if (error.response) {
            return error.response.statusText;
          } else {
            AppService.logSupportDebug('equipment.ts - listAllLocationTypes - 49 - ' + error);
            return Store.getters['app/messages'].couldNotConnect;
          }
        }); 
      } else {
        // this data is ready to access in the Store
        return '';
      }
  },

  async getById(config: {params: { id: number }}) {
    return Api().get('/equipment/get', config);
  },

  async listTagsByType(config: {params: { equipmentTypeId: number }}) {
    return Api().get('/equipment/list_tags_by_type', config);
  },
};

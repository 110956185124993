













































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import PencilDateCalendar from '@/components/app/PencilDateCalendar.vue';
import PencilDateDialog from '@/components/app/PencilDateDialog.vue';
import ReadMore from '@/components/app/ReadMore.vue';

// --- Models ---
import { EquipmentItem, Tag } from '@/models/Equipment.model';
import { LocationType } from '@/models/App.model';
import { Shoot } from '@/models/Shoot.model';

// --- Services ---
import AppService from '@/services/app';
import EquipmentService from '@/services/equipment';
import ShootService from '@/services/shoot';

// --- Third Party imports ---
import Hashids from 'hashids';
import dayjs from 'dayjs';

@Component({
  components: {
    PencilDateCalendar,
    PencilDateDialog,
    ReadMore,
  },
})
export default class EquipmentDetail extends Vue {
  @Prop(String) readonly shootId!: string;
  @Prop(String) readonly equipmentId!: string;

  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);

  isLoading: boolean = false;

  tab: number = 0;
  clickedItem: EquipmentItem = {
    id: 0,
    equipmentTypeId: 0,
    imageFilename: '',
    regionId: 0,
    region: '',
    brandId: 0,
    brand: 'Canon',
    model: '',
    categoryId: 0,
    category: 'Film',
    subCategoryId: 0,
    subCategory: 'Lighting',
    currentRate: 5999.99,
    shootTypeId: 0,
    shootType: '',
  };
  shootItem: Shoot = {
    id: 0,
    name: '',
    shootTypeId: 0,
    shootType: '',
    regionId: 0,
    region: '',
    startDate: '',
    endDate: '',
    canDelete: false,
  };

  tags: Tag[] = [];

  equipmentImages: Array<{img: string, name: string}> = [];
  equipmentImagesLength: number = 0;
  mainImageSettings: object = {
    fade: true,
  };
  multipleImageSettings: object = {
    slidesToShow: 3,
    focusOnSelect: true,
  };

  showPencilDateDialog: boolean = false;
  isExistingBooking: boolean = false;
  pencilDateStart: string = '';
  pencilDateEnd: string = '';
  pencilDateCurrentStart: string = '';
  pencilDateCurrentEnd: string = '';
  pencilBookingId: number = -1;
  shootDuration: number = 0;

  $refs!: {
    tabContent: HTMLFormElement,
  };
  
  // #region Functions to display equipment data on page load
    getEquipmentPhotoUrl() {
      // first check if the photo comes from the Equipment Type
      // if not, get it from the equipment
      // there should never not be one of them

      if (this.clickedItem.typeImageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment_type/' + this.clickedItem.typeImageFilename;
      } else if (this.clickedItem.imageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment/' + this.clickedItem.imageFilename;
      } else {
        return '';
      }
    }

    getBrandLogoUrl() {
      if (this.clickedItem.brandLogoFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'brand_logos/' + this.clickedItem.brandLogoFilename;
      } else {
        return '';
      }
    }

    getDescription() {
      return this.clickedItem.typeDescription ? this.clickedItem.typeDescription : this.clickedItem.description;
    }
  // #endregion

  // #region Functions to display shoot equipment detail data on page load
    async beforeMount() {
      const shootId: string = this.hashids.decode(this.shootId + '') + '';
      const equipmentId: string = this.hashids.decode(this.equipmentId + '') + '';

      // check if we actually have a shootId
      if (shootId && shootId.length > 0) {
        // TODO:: try parse checks
        // get all the shoots and find it!
        await ShootService.listAll(false);

        // find our item in there
        this.shootItem = this.$store.getters['shoots/shoots'].find( (item: Shoot) =>  item.id === parseInt(shootId, 10));

        if (equipmentId && equipmentId.length > 0) {
          this.clickedItem.id = parseInt(equipmentId, 10);

          await this.getClickedItem();

          // now go and fetch the tags for this equipment type
          await this.getTagsByEquipmentType();

          // this can be used to extend the images when products get vendor images
          this.equipmentImages = [
            {
              img: this.getEquipmentPhotoUrl(),
              name: this.clickedItem.model,
            },
          ];

          if (this.equipmentImages.length > 1 ) {
            this.mainImageSettings = {
              ...this.mainImageSettings, 
              ...{
                asNavFor: '$refs.c2',
                focusOnSelect: true,
                },
            };
              
            this.multipleImageSettings = {
              ...this.multipleImageSettings,
              ...{
                asNavFor: '$refs.c1',
              },
            };
          }

          // this needs to be done after we have the equipment item because it is based on the equipment id
          this.shootDuration = ( dayjs(this.shootItem.endDate).diff(dayjs(this.shootItem.startDate)) / 86400000 ) + 3;
        } else {
          // kick  them out, how did they even get here?!
          this.$router.push({ name: 'My Shoots' });
        }
      } else {
        // kick  them out, how did they even get here?!
        this.$router.push({ name: 'My Shoots' });
      }
    }

    async getClickedItem() {                 
      await EquipmentService.getById({ params: {id: this.clickedItem.id } })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              // there was an issue getting this Equipment Item, redirect them back to the Shoot View 
              AppService.errorHandler(response.data.message);
              this.$router.push({ name: 'My Shoots' });
            } else {
              this.clickedItem = response.data;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('Equipment_View.vue - getClickedItem - 384 - ' + JSON.stringify(response));
          }
        });
    }

    async getTagsByEquipmentType() {
      try {        
        const response = await EquipmentService.listTagsByType( { params: { equipmentTypeId: this.clickedItem.equipmentTypeId }});
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false' || response.data.length < 1) {
            AppService.logSupportDebug('Equipment_View.vue - getTagsByEquipmentType - 395 - ' + response.data.message);
            // do nothing, this just won't display stuff to the user, it shouldn't stop them viewing the item
          } else {
            this.tags = response.data;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }
      } catch (error) {
        AppService.logSupportDebug('Equipment_View.vue - getTagsByEquipmentType - 405- ' + error);
        // do nothing, this just won't display stuff to the user, it shouldn't stop them viewing the item
      }
    }

  // #endregion
  
  onDateClicked(
      itemDate: {
        startDate: string, 
        maxDate: string, 
        pencilBookingId: number, 
        pencilBookingStartDate: string, 
        pencilBookingEndDate: string,
    }) {
    this.pencilDateStart = itemDate.startDate;
    this.pencilDateEnd = itemDate.maxDate;
    this.pencilDateCurrentStart = itemDate.pencilBookingId > -1 ? itemDate.pencilBookingStartDate : '';
    this.pencilDateCurrentEnd = itemDate.pencilBookingId > -1 ? itemDate.pencilBookingEndDate : '';
    this.pencilBookingId = itemDate.pencilBookingId;
    this.showPencilDateDialog = true;
  }

  async onPencilDateDialogConfirmed(dialogData: {startDate: string, endDate: string, locationTypes: LocationType[], travelDays: string }) {
    this.$router.push({ name: 'Shoot Detail', params: { 
      id: '' + this.hashids.encode(this.shootItem.id),
    } } ); 
    // handle the redirect here
  }
}
